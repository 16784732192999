import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';
import CheckSvg from '../../public/images/common/check.svg';
import { CloseButton } from '../Buttons/Buttons';
import {useState, useEffect} from 'react'
//import PrivateGameTypeStep from './PrivateGameTypeStep';
import PlayersNumberStep from './PlayersNumberStep';
import FriendsStep from './FriendsStep';
import NameStep from './NameStep';
//import DateStep from './DateStep';
import useTranslation from 'next-translate/useTranslation';
import { cdnLoader } from '../../config/cdnImageLoader.config';

import { useDispatch } from 'react-redux';
import { getFriendList } from '../../store/friends/friendActions';

const PrivateMM = (props) => {

  const {
    friendsListAlreadyRequested,
    gameSetup,
    friendsList,
    createPrivateLobby,
    fromLobby,
    closeFunction,
  } = props

    const dispatch = useDispatch();
    useEffect(() => {
      if(!friendsListAlreadyRequested){
        dispatch(getFriendList());
      }
    }, []);

  const {t} = useTranslation()
  const [currentStep, setNextStep] = useState(1);
  //const [showExtraStep, setShowExtraStep] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [privateGameMode, setPrivateGameMode] = useState(null);
  const [hour, setHour] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [playerNumber, setPlayerNumber] = useState(null);
  const [selectedFriendsIds, setSelectedFriendsIds] = useState([])
  const [gameName, setGameName] = useState(t('common:inputs.update_game_name.value'));
  const [gameCreating, setGameCreating] = useState(false)

  const getMonthName = (monthNumber) => {
    switch (monthNumber) {
      case 0: return 'JAN';
      case 1: return 'FEB';
      case 2: return 'MAR';
      case 3: return 'APR';
      case 4: return 'MAY';
      case 5: return 'JUN';
      case 6: return 'JUL';
      case 7: return 'AUG';
      case 8: return 'SEP';
      case 9: return 'OCT';
      case 10: return 'NOV';
      case 11: return 'DEC';
    }
  }

  useEffect(() => {
    const hour = startDate.getHours() + 1
    setHour(hour);
    setStartDate(prevDate => {
      return new Date(prevDate.setHours(hour, 0))
    })
    setMinutes(0)
    if (fromLobby) {
      setNextStep(3)
      setPlayerNumber(gameSetup.freeSpaces + 1)
    }
  }, [])

  const handleHourChange = (inc, overRide = false) => {
    // const hour = +event.target.value;
    if (overRide) {
      setHour(inc)
      return
    }
    if (hour + inc < 0) {
      setHour(23)
      return
    } else if (hour + inc > 23) {
      setHour(0)
      return
    }
    setHour(prevState => prevState + inc)
  }

  const handleMinuteChange = (inc, overRide = false) => {
    if (overRide) {
      setMinutes(inc)
      return
    }
    if (minutes + inc < 0) {
      setMinutes(59)
      return
    } else if (minutes + inc > 59) {
      setMinutes(0)
      return
    }
    setMinutes(prevState => prevState + inc)
  }

  useEffect(() => {
    setStartDate(prevState => {
      return new Date(prevState.setHours(hour))
    })
  }, [hour])

  useEffect(() => {
    setStartDate(prevState => {
      return new Date(prevState.setMinutes(minutes, 0, 0))
    })
  }, [minutes])

  // const hanldeMinuteChange = (event) => {
  //   const minute = +event.target.value;
  //   setMinutes(minute)
  //   setStartDate(prevState => {
  //     return new Date(prevState.setMinutes(minute, 0, 0))
  //   })
  // }

  const getProperTimeFormat = (time) => {
    return time < 10 ? '0' + time.toString() : time.toString()
  }

  const handleChoosePlayerNumber = (playerNumber, nextStep) => {
    if (playerNumber > 4) {
      // alert('coming soon')
      return
    }
    setPlayerNumber(playerNumber)
    // if (playerNumber === 6 && gameSetup.selectedGameMode.value === 'team') {
    //   setShowExtraStep(true)
    // } else {
    //   setShowExtraStep(false)
    // }
    setNextStep(nextStep)
  }

  const handlePrivateGameModeChange = (privateGameMode, nextStep) => {
    setPrivateGameMode(privateGameMode)
    if (privateGameMode === 'scheduled') {
      setShowExtraStep(true)
    } else {
      setShowExtraStep(false)
    }
    setNextStep(nextStep)
  }

  const handleCreatePrivateLobby = () => {
    if (gameCreating) {
      return
    }
    let dateString = '';
    const privateGameMode = 'instant'
    if (privateGameMode === 'instant') {
      const date = new Date()
      dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    } else {
      dateString = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()} ${startDate.getHours()}:${startDate.getMinutes()}`
    }
    // debugger;
    const lobbyData = {
      players: playerNumber,
      members: selectedFriendsIds,
      type: privateGameMode,
      date: dateString,
      name: gameName.length > 0 ? gameName : 'Friends Game'
    }

    createPrivateLobby(lobbyData)
    setGameCreating(true)
  }

  const friendIsSelected = (userId) => {
    return selectedFriendsIds.indexOf(userId) >= 0
  }

  const getStepTitle = (step) => {
    if (step === 1) {
      return t('wizard:friends_game.players_number_step_desc')
    } else if (step === 2) {
      return t('wizard:friends_game.friends_step_desc')
    } else if (step === 3) {
      return t('wizard:friends_game.game_name_step_desc')
    }
  }
  // const getStepTitle = (step) => {
  //   if (step === 1) {
  //     return t('wizard:friends_game.game_type_step_desc')
  //   } else if (step === 2 && showExtraStep) {
  //     return t('wizard:friends_game.date_time_step')
  //   } else if ((showExtraStep && step === 3) || (!showExtraStep && step === 2)) {
  //     return t('wizard:friends_game.players_number_step_desc')
  //   } else if ((showExtraStep && currentStep === 4) || (!(showExtraStep) && currentStep === 3)) {
  //     return t('wizard:friends_game.friends_step_desc')
  //   } else if ((showExtraStep && currentStep === 5) || (!(showExtraStep) && currentStep === 4)) {
  //     return t('wizard:friends_game.game_name_step_desc')
  //   }
  // }

  const handleFriendsChoose = (userId) => {
    if (friendIsSelected(userId)) {
      setSelectedFriendsIds(prevState => prevState.filter(seletedUserId => seletedUserId !== userId))
    } else {
      if ((selectedFriendsIds.length ) + 1 === playerNumber) {
        if (playerNumber === 2) {
          setSelectedFriendsIds(() => [userId])
        }
        //first +1 is for the player who is creating lobby, second +1 is for checking if adding player don't exceed chosen player number
        // alert('too much players')
        return
      }
      setSelectedFriendsIds(prevState => [...prevState, userId])
    }
  }

  const extended = () => {
    if (currentStep === 2 || currentStep === 3) {
        return true
      }
    return false
  }
  // const extended = () => {
  //   if (
  //     showExtraStep && currentStep === 2 ||
  //     ((showExtraStep && currentStep === 4) || (!(showExtraStep) && currentStep === 3)) ||
  //     ((showExtraStep && currentStep === 5) || (!(showExtraStep) && currentStep === 4))
  //     ) {
  //       return true
  //     }
  //   return false
  // }

  return (
    <div className = {`${commonStyles.wizard_container} ${extended() && commonStyles.extended}`}>
      <div className = {commonStyles.upper_part}>
        <div className = {commonStyles.wizard_head}>
          <div className = {commonStyles.wizard_details_container}>
            <div className = {`${commonStyles.wizard_image_container} ${commonStyles.private}`}>
              <img className = {commonStyles.wizard_image} src = {cdnLoader(`/images/dashboard/private_bottom.svg`)} />
            </div>
            <div className = {commonStyles.wizard_title_container}>
              <h2 className = {commonStyles.wizard_title}>{t('wizard:friends_game.title')}</h2>
            </div>
          </div>
          <div className = {commonStyles.wizard_close_button_container}>
            <CloseButton click = {closeFunction} />
          </div>
        </div>
        <div className = {commonStyles.dynamic_part}>
          <h4 className = {commonStyles.dynamic_title}>{getStepTitle(currentStep)}</h4>
          <div className = {commonStyles.dynamic_body}>
            {/* {currentStep === 1 && (
              <PrivateGameTypeStep 
                handlePrivateGameModeChange = {handlePrivateGameModeChange}
                privateGameMode = {privateGameMode}
                currentStep = {currentStep}
              />              
            )} */}
            {/* {showExtraStep && currentStep === 2 && (
              <>
                <DateStep
                  startDate = {startDate}
                  setStartDate = {setStartDate}
                  handleHourChange = {handleHourChange}
                  hanldeMinuteChange = {handleMinuteChange}
                  getProperTimeFormat = {getProperTimeFormat}
                  minutes = {minutes}
                  hour = {hour}
                  getMonthName = {getMonthName}
                />
              </>
            )} */}
            {currentStep === 1 && (
              <PlayersNumberStep
                playersNumber = {gameSetup.playersNumbers}
                handleChoosePlayerNumber = {handleChoosePlayerNumber}
                selectedPlayerNumber = {gameSetup.selectedPlayerNumber}
                currentStep = {currentStep}
              />
            )}
            {currentStep === 2 && (
              <FriendsStep 
                friendsList = {friendsList} 
                handleFriendsChoose = {handleFriendsChoose} 
                friendIsSelected = {friendIsSelected}
                />
            )}
            {currentStep === 3 && (
              <NameStep
                gameName = {gameName}
                setGameName = {setGameName}
              />
            )}
          </div>
        </div>
      </div>
      <div className = {`${commonStyles.bottom_part}`}>
        <div className = {commonStyles.left_side}></div>
        <div className = {commonStyles.center}>
          <div className = {commonStyles.setpper_container}>
            {/* <div 
              className = {`${commonStyles.stepper_part} ${currentStep === 1 ? commonStyles.current : ''} ${currentStep > 1 ? commonStyles.active : ''} ${commonStyles.circles}`}
              onClick = {() => {
                setShowExtraStep(false)
                setPlayerNumber(null)
                setSelectedFriendsIds([])
                setNextStep(1)
              }}
              >
              <h5 className = {commonStyles.stepper_title}>{t('wizard:friends_game.type')}</h5>
              <div className = {commonStyles.stepper_circle}></div>
              <h5 className = {commonStyles.setpper_value}>{t('wizard:friends_game.instant')}</h5>
            </div>
            <div className = {`${commonStyles.stepper_part} ${currentStep > 1 ? commonStyles.active : ''} ${commonStyles.line}`}>
              <div className = {commonStyles.stepper_line}></div>
            </div> */}

            {/* <div 
              className = {`${commonStyles.stepper_part} ${((showExtraStep && currentStep === 2) || (!showExtraStep && currentStep > 1 )) ? commonStyles.current : ''} ${((showExtraStep && currentStep > 2) || (!showExtraStep && currentStep > 1)) ? commonStyles.active : ''} ${commonStyles.circles}`}
              onClick = {() => {
                if (privateGameMode) {
                  setPlayerNumber(null)
                  setSelectedFriendsIds([])
                  setNextStep(2)
                }
              }}
              >
              <h5 className = {commonStyles.stepper_title}>{t('wizard:friends_game.date_and_time')}</h5>
              <div className = {commonStyles.stepper_circle}></div>
              <h5 className = {commonStyles.setpper_value}>
                {privateGameMode === 'instant' && t('wizard:friends_game.now')}
                {privateGameMode === 'scheduled' && 
                `${getProperTimeFormat(startDate.getDate())}/${getMonthName(startDate.getMonth())}/${getProperTimeFormat(startDate.getHours())}:${getProperTimeFormat(startDate.getMinutes())}`}
              </h5>
            </div> */}
            {/* <div className = {`${commonStyles.stepper_part} ${((showExtraStep && currentStep > 2) || (!showExtraStep && currentStep > 1)) ? commonStyles.active : ''} ${commonStyles.line}`}>
              <div className = {commonStyles.stepper_line}></div>
            </div> */}

            <div 
              className = {`${commonStyles.stepper_part} ${(currentStep === 1) ? commonStyles.current : ''} ${(currentStep > 1) ? commonStyles.active : ''} ${commonStyles.circles}`}
              onClick = {() => {
                if (startDate && privateGameMode) {
                  setSelectedFriendsIds([])
                  setNextStep(1)
                }
              }}
              >
              <h5 className = {commonStyles.stepper_title}>{t('wizard:friends_game.players')}</h5>
              <div className = {commonStyles.stepper_circle}></div>
              <h5 className = {commonStyles.setpper_value}>{playerNumber}</h5>
            </div>
            <div className = {`${commonStyles.stepper_part} ${(currentStep > 1) ? commonStyles.active : ''} ${commonStyles.line}`}>
              <div className = {commonStyles.stepper_line}></div>
            </div>

            <div 
              className = {`${commonStyles.stepper_part} ${selectedFriendsIds.length === playerNumber - 1? (commonStyles.filled + ' ' + commonStyles.active) : '' } ${(currentStep === 2) ? commonStyles.current : ''} ${(currentStep > 2) ? commonStyles.active : ''} ${commonStyles.circles}`}
              onClick = {() => {
                if (playerNumber) {
                  setNextStep(2)
                }
              }}
              >
              <h5 className = {commonStyles.stepper_title}>{t('wizard:friends_game.friends')}</h5>
              <div className = {commonStyles.stepper_circle}>
                <div className = {commonStyles.checkmark}>
                  <CheckSvg />
                </div>
              </div>
              {playerNumber && <h5 className = {commonStyles.setpper_value}>{selectedFriendsIds.length }/{playerNumber - 1}</h5>}
            </div>
            <div className = {`${commonStyles.stepper_part} ${(currentStep > 2) ? commonStyles.active : ''} ${commonStyles.line}`}>
              <div className = {commonStyles.stepper_line}>                
              </div>
            </div>

            <div className = {`${commonStyles.stepper_part} ${commonStyles.circles} ${(currentStep > 2) ? (commonStyles.active + ' ' + commonStyles.filled) : ''}`}>
              <h5 className = {commonStyles.stepper_title}>{t('wizard:friends_game.name')}</h5>
              <div className = {commonStyles.stepper_circle}>
                <div className = {commonStyles.checkmark}>
                  <CheckSvg />
                </div>
              </div>
              {/* <h5 className = {commonStyles.setpper_value}>Solo</h5> */}
            </div>
          </div>
        </div>
        {/* {showExtraStep && currentStep === 2 && (
          <div className = {`${commonStyles.right_side}`}>
            <div className = {`${commonStyles.search_game_container}`}>
              <button
                className = {`${commonStyles.search_game}`} type="button"
                onClick = {() => {
                  if (showExtraStep) {
                    setNextStep(3)
                  } else {
                    setNextStep(2)
                  }
                }}
                >
                {t('common:buttons.continue')}
              </button>
            </div>
          </div>
        )} */}
        {currentStep === 2 && (
          <div className = {commonStyles.right_side}>
            <div className = {`${commonStyles.search_game_container}`}>
              <button
                className = {`${commonStyles.search_game}`} type="button"
                disabled = {!(selectedFriendsIds.length === playerNumber - 1)}
                onClick = {() => {setNextStep(3)}}
                >
                {t('common:buttons.continue')}
              </button>
            </div>
          </div>
        )}
        {(currentStep === 3)
          && (
            <div className = {commonStyles.right_side}>
              <div className = {`${commonStyles.search_game_container}`}>
                <button
                  className = {`${commonStyles.search_game}`} type="button"
                  disabled = {gameCreating}
                  onClick = {() => {handleCreatePrivateLobby()}}
                  >
                    {gameCreating ?  t('common:buttons.creating_game') : t('common:buttons.create_game')}
                </button>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default PrivateMM